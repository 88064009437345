import { FilterType } from "mui-datatables";
import { ReactFragment } from "react";

export class TableDefinition<T> {
    constructor(public rows: T[]) {
      this.columns = [
        new ColumnDefinition("Raw data", (r) => r, () => null, false, null)
      ]
    }

    getRowData = () => {
      return this.rows.map(row => {
        const data = this.columns.map(c => c.value(row));
        debugger;

        return data;
      });
    }
  
    addColumn(title: string, value: (row: T) => string | null | undefined | ReactFragment | number, stringValue: (row: T) => string | null | undefined, display: boolean = true, filterType: FilterType | null = 'multiselect') {
      this.columns.push(new ColumnDefinition<T>(title, value, stringValue, display, display ? filterType : null));
  
      return this;
    }
  
    addStringColumn(title: string, value: (row: T) => string | null | undefined, display: boolean = true, filterType: FilterType | null = 'multiselect') {
      this.columns.push(new ColumnDefinition<T>(title, value, value, display, display ? filterType : null));
  
      return this;
    }
  
    addNumberColumn(title: string, value: (row: T) => number | null, display: boolean = true, filterType: FilterType | null = 'multiselect') {
      this.columns.push(new ColumnDefinition<T>(title, value, row => value(row) === null ? null : value(row) + "", display, display ? filterType : null));
  
      return this;
    }

    addFilterOnlyColumn(title: string, value: (row: T) => string, filterType: FilterType)
    {
        this.columns.push(new ColumnDefinition<T>(title, value, value, false, filterType));
        
        return this;
    }
  
    columns: ColumnDefinition<T>[];
  }
  
 export class ColumnDefinition<T> {
    constructor(public title: string, public value: (row: T) => string | null | undefined | ReactFragment | number, public stringValue: (row: T) => string | null | undefined, public display: boolean, public filterType: FilterType | null) {
  
    }

    sortCompare = (order: any) => (r: any, b: any) => {

      var c1 = r.rowData[0] as T;
      var c2 = b.rowData[0] as T;

      var ascDesc = order === "asc" ? 1 : -1;


      return ascDesc * ((this.stringValue(c1) || "").localeCompare(this.stringValue(c2) || "", 'en', { numeric: true }));
    }
  }