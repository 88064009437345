import {
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { ApiClient, ExamineeFaceCompareViewModel } from "../ApiHelper";
import ExamList from "./recording/ExamList";
import { FaceCompareSnapshotModel } from "../Api";
import { Check, CheckCircle } from "@mui/icons-material";
import HelpIcon from '@mui/icons-material/Help';
import ReportIcon from '@mui/icons-material/Report';

function FaceCompareIndex() {
  class Filter {
    constructor(public name: string, public filter: (r: ExamineeFaceCompareViewModel) => boolean) {
    }
  }

  const filters = [
    new Filter("Ikke godkendte", r => r.automaticFaceCompareResult === false && r.manualFaceCompareResult === null),
    new Filter("Ikke godkendte - inkl. manuelt gennemgåede", r => r.automaticFaceCompareResult === false),
    new Filter("Godkendte", r => r.automaticFaceCompareResult === true),
    new Filter("Godkendte - ikke tjekket manuelt", r => r.automaticFaceCompareResult === true && r.manualFaceCompareResult === null),
    new Filter("Handling: Rykket", r => r.manualFaceCompareResult === false),
    new Filter("Handling: Godkendt", r => r.manualFaceCompareResult === true),
    new Filter("Mangler analyse", r => r.automaticFaceCompareResult === null),
    new Filter("Alle", r => true),
  ];

  const [items, setItems] = useState<null | ExamineeFaceCompareViewModel[]>(null);

  const [filter, setFilter] = useState<Filter>(filters[0]);
  const [searchText, setSearchText] = useState("");

  const reset = () => {
    setFilter(filters[0]);
    setSearchText("");
  }

  const [snapshotImages, setSnapshotImages] = useState<null | FaceCompareSnapshotModel[]>(null);
  const [scoreSnapshotsRunning, setScoreSnapshotsRunning] = useState(false);

  const [showDialog, setShowDialog] = useState(false);

  const [index, setIndex] = useState(-1);

  const reload = async () => {
    const newItems = await ApiClient.getFaceCompares();

    setItems(newItems);
  };

  const getFaceCompareSnapshots = async () => {
    if (index < 0 || filteredItems === null) {
      setSnapshotImages(null);
      return;
    }

    setSnapshotImages(await ApiClient.getFaceCompareSnapshots(filteredItems[index].sessionId));
  };

  const manualResult = async (row: ExamineeFaceCompareViewModel, result: boolean | null) => {
    if (result === null) {
      await ApiClient.api.session.facecompareManualresultResetCreate(row.sessionId);
    } else {
      await ApiClient.api.session.facecompareManualresultCreate(row.sessionId, result);
    }
    await reload();
  }

  const scoreSnapshots = async (row: ExamineeFaceCompareViewModel) => {
    setScoreSnapshotsRunning(true);
    await ApiClient.scoreSnapshots(row.sessionId)
    await reload();
    setScoreSnapshotsRunning(false);
  }

  useEffect(() => {
    reload();

    const intervalId = setInterval(reload, 10000);

    return () => clearInterval(intervalId); //This is important
  }, []);

  useEffect(() => {
    {
      getFaceCompareSnapshots();
    }
  }, [index]);

  if (items === null) {
    return <CircularProgress />;
  }


  const filteredItems = items.filter(r => {
    if (!filter.filter(r)) {
      return false;
    }

    if (searchText) {

      if (r.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) === -1 && r.username.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) === -1) {
        return false;
      }

    }

    return true;
  });

  const selectedItem = filteredItems[index];

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit">
          ID-kort godkendelse
        </Link>
      </Breadcrumbs>

      <Paper sx={{ mb: 2, p: 2 }}>

        <Box sx={{ mb: 2, mt: 2, display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1 }}>
            <InputLabel>Filter</InputLabel>
            <Select
              label="Filter"
              onChange={v => setFilter(filters.filter(f => f.name === v.target.value)[0])}
              value={filter.name}
            >
              {filters.map((filter) => (
                <MenuItem key={filter.name} value={filter.name}>{filter.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1 }}>
            <TextField placeholder="Søg efter tilmeldte" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          </FormControl>

          <Button onClick={reset}>Nulstil</Button>
        </Box>
      </Paper>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Tidspunkt</TableCell>
              <TableCell>Brugernavn</TableCell>
              <TableCell>Navn</TableCell>
              <TableCell>Prøve</TableCell>
              <TableCell>Id kort</TableCell>
              <TableCell>Resultat</TableCell>
              <TableCell>Handling</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map((row, i) => (
              <TableRow
                key={row.sessionId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.recordingStart.toFormat("d/M kl. HH:mm")}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.username}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.examName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">

                  <img src={ApiClient.api.baseUrl + `/session/${row.sessionId}/idcard`} style={{ maxWidth: "150px", maxHeight: "75px", margin: "-15px" }} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {getFaceDetectionIcon(row.automaticFaceCompareResult)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {getFaceDetectionIcon(row.manualFaceCompareResult)}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Button onClick={() => { setIndex(i); setShowDialog(true); }}>Rediger</Button>
                  <Button disabled={false && scoreSnapshotsRunning} onClick={() => scoreSnapshots(row)}>Kør analyse</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>

      <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="xl">
        {selectedItem && <>
          <DialogTitle>{selectedItem.name} - {selectedItem.username} - <a href={"/app/recordings/examinee/" + selectedItem.examineeId}>Gå til optagelse</a></DialogTitle>
          <DialogContent>

            <Paper elevation={4} sx={{ p: 2 }}>
              <Grid container style={{ minWidth: "1200px" }}>
                <Grid item>
                  <img src={ApiClient.api.baseUrl + `/session/${selectedItem.sessionId}/idcard`} style={{ maxWidth: "400px" }} />
                </Grid>

                <Grid item>
                  <video src={ApiClient.api.baseUrl + `/recording/stream/video/${selectedItem.recordingId}`} autoPlay={false} controls style={{ maxWidth: "400px" }}></video>
                </Grid>
              </Grid>
            </Paper>

            <Paper elevation={4} sx={{ p: 2, mt: 4 }}>
              <Grid container style={{ minWidth: "1200px" }}>
                {!snapshotImages?.length && <div>Ingen snapshot eksempler tilgængelige</div>}
                {snapshotImages?.map(x =>

                  <Grid key={x.id} item>
                    <div>
                      <Badge color={getIconColor(x.score === null ? null : x.score! < 0.6)} badgeContent={x.score}>
                        {getFaceDetectionIcon(x.score === null ? null : x.score! < 0.6)}
                      </Badge>

                      { }<br />

                      <img style={{ maxWidth: "400px" }} src={"data:image/webp;base64," + x.bytes} />
                    </div>
                  </Grid>
                )}
              </Grid>
            </Paper>

            <Box>
              <Button onClick={() => manualResult(selectedItem, false)}>Marker som rykket</Button>
              <Button onClick={() => manualResult(selectedItem, true)}>Marker som godkendt</Button>
              <Button onClick={() => manualResult(selectedItem, null)}>Nulstil manuel markering</Button>
            </Box>


          </DialogContent>
          <DialogActions>
            <Button disabled={index < 1} onClick={() => setIndex(index - 1)}>Forrige</Button>
            <Button disabled={index >= filteredItems.length - 1} onClick={() => setIndex(index + 1)}>Næste</Button>
          </DialogActions>

        </>}
      </Dialog>
    </>
  );
}


const getIconColor = (result: boolean | null | undefined): 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning' => {
  if (result === false) {
    return "error";
  } else if (result === true) {
    return "success";
  } else {
    return "info";
  }
}

export const getFaceDetectionIcon = (result: boolean | null | undefined) => {
  if (result === false) {
    return <ReportIcon color={getIconColor(result)} fontSize="large" />
  } else if (result === true) {
    return <CheckCircle color={getIconColor(result)} fontSize="large" />
  } else {
    return <HelpIcon color={getIconColor(result)} fontSize="large" />
  }
}

export default FaceCompareIndex;
